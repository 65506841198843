import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import RegisterBot from './components/RegisterBot/RegisterBot';
import Logout from './Logout';
// import Home from './components/Home/Home';
import LoginMain from './components/Login/LoginMain';
import RegisterMain from './components/Register/RegisterMain';
// import MemberMain from './components/Member/MemberMain';
// import MemberInfo from './components/Member/MemberInfo';
import MemberDeposit from './components/Member/MemberDeposit';
import MemberDepositGGPayment from './components/Member/MemberDepositGGPayment';
import MemberDepositScb from './components/Member/MemberDepositScb';
import MemberWithdraw from './components/Member/MemberWithdraw';
import PromotionMain from './components/Promotion/PromotionMain';

// NEW
import GameMain from './components/GameMain/GameMain';
import GameMore from './components/GameMain/GameMore';

import ReturnCredit from './components/ReturnCredit/ReturnCredit';
import ReturnFriendCredit from './components/ReturnCredit/ReturnFriendCredit';

// CSS
import './assets/css/index.css';
import InviteFriend from './components/InviteFriend/InviteFriend';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    // {
    //     path: '/login', element: <LoginMain />
    // },
    // {
    //     path: '/register', element: <RegisterMain />
    // },
    // {
    //     path: '/logout', element: <Logout />
    // },
    // {
    //     path: '/registerbot', element: <RegisterBot />
    // },
    // {
    //     path: '/promotions', element: <PromotionMain />
    // },
    // {
    //     path: '/return-credit', element: <ReturnCredit />
    // },
    // {
    //     path: '/return-friend-credit', element: <ReturnFriendCredit />
    // },
    // {
    //     path: "/affiliate", element: <InviteFriend />
    // },
    // {
    //     element: <App />,
    //     children: [
    //         {
    //             path: "/", element: <GameMain />
    //         },
    //         // {
    //         //     path: "/member", element: <MemberMain />
    //         // },
    //         // {
    //         //     path: "/memberinfo", element: <MemberInfo />
    //         // },
    //         {
    //             path: "/game/:getGameCategory", element: <GameMore />
    //         },
    //         {
    //             path: "/deposit", element: <MemberDeposit />
    //         },
    //         {
    //             path: "/depositpayment", element: <MemberDepositGGPayment />
    //         },
    //         {
    //             path: "/depositscb", element: <MemberDepositScb />
    //         },
    //         {
    //             path: "/withdraw", element: <MemberWithdraw />
    //         },
    //     ]
    // },
    {
        path: '*', element: <App />
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
